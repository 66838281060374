var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "home" },
    [
      _c("home-banner"),
      _vm._v(" "),
      _c("six-th", { staticClass: "d-none d-md-block" }),
      _vm._v(" "),
      _c("beware-of-scams", { staticClass: "d-none d-md-block" }),
      _vm._v(" "),
      _c(
        "who-are-we",
        [
          _c("six-th", { staticClass: "mb-show" }),
          _vm._v(" "),
          _c("beware-of-scams", { staticClass: "mb-show" }),
        ],
        1
      ),
      _vm._v(" "),
      _c("why-choose-us"),
      _vm._v(" "),
      _c("home-user"),
      _vm._v(" "),
      _c("home-activity", { attrs: { "blog-list": _vm.blogList } }),
      _vm._v(" "),
      _c("partners"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }