<!--
 * @Author: zhaoyang
 * @Date: 2023-06-28 19:55:46
 * @Last Modified by: zhaoyang
 * @Last Modified time: 2024-11-13 14:40:17
-->

<template>
    <div>
        <div class="home-banner-pc d-none d-md-block">
            <swiper
                ref="mySwiper"
                style="z-index: 2;"
                :options="swiperOptions"
            >
                <swiper-slide>
                    <div
                        class="slide-box"
                        style="background: #10a437;"
                    >
                        <div class="slide">
                            <!-- <ec-image :webp="require('./img/b1.webp')"> -->
                            <img
                                class="banner-one-new"
                                src="./img/b1.jpg"
                            >
                            <!-- </ec-image> -->
                        </div>
                    </div>
                </swiper-slide>
                <swiper-slide>
                    <div
                        class="slide-box"
                        style="background: #efd5b6;"
                    >
                        <div class="slide">
                            <!-- <ec-image :webp="require('./img/b2.webp')"> -->
                            <img
                                class="banner-one-new"
                                src="./img/b2.jpg"
                            >
                            <!-- </ec-image> -->
                        </div>
                    </div>
                </swiper-slide>
                <swiper-slide>
                    <div
                        class="slide-box"
                        style="background: #dadde3;"
                    >
                        <div class="slide">
                            <!-- <ec-image :webp="require('./img/b3.webp')"> -->
                            <img
                                loading="lazy"
                                class="banner-one-new"
                                src="./img/b3.jpg"
                            >
                            <!-- </ec-image> -->
                        </div>
                    </div>
                </swiper-slide>
                <swiper-slide>
                    <div
                        class="slide-box"
                        style="background: #dadde3;"
                    >
                        <div class="slide">
                            <!-- <ec-image :webp="require('./img/b4.webp')"> -->
                            <img
                                loading="lazy"
                                class="banner-one-new"
                                src="./img/b4.jpg"
                            >
                            <!-- </ec-image> -->
                        </div>
                    </div>
                </swiper-slide>
                <div class="content">
                    <div class="content-box ec-container">
                        <!-- 0 -->
                        <div
                            v-if="realIndex===0"
                            class="my-poa"
                        >
                            <div
                                class="sub-poa"
                                :class="{
                                    'sub-poa-active': needDh
                                }"
                            >
                                <div class="content-title">
                                    <span>{{ getI18n('bt1') }}</span>
                                </div>
                                <div class="text">
                                    {{ getI18n('desc') }}
                                    <img
                                        :class="[$i18n.locale === 'en' ? 'content-icon-2' : 'content-icon-1']"
                                        src="../../component/img/shield-aman.png"
                                    >
                                </div>
                            </div>

                            <div class="btn-group">
                                <app-store-button type="video" />
                                <app-store-button
                                    unique-id="Banner1"
                                    type="google"
                                />
                                <app-store-button
                                    unique-id="Banner1"
                                    type="apple"
                                />
                            </div>
                        </div>
                        <!-- 1 -->
                        <div
                            v-if="realIndex===1"
                            class="my-poa"
                        >
                            <div
                                class="sub-poa"
                                :class="{
                                    'sub-poa-active': needDh
                                }"
                            >
                                <div class="content-title">
                                    <span>{{ getI18n('bt2') }}</span>
                                    <span class="por">
                                        {{ getI18n('btt2') }}
                                    </span>
                                </div>
                                <div class="text">
                                    {{ getI18n('desc') }}
                                    <img
                                        src="../../component/img/shield-aman.png"
                                        :class="[$i18n.locale === 'en' ? 'content-icon-2' : 'content-icon-1']"
                                    >
                                </div>
                            </div>

                            <div class="btn-group">
                                <app-store-button type="video" />
                                <app-store-button
                                    unique-id="Banner2"
                                    type="google"
                                />
                                <app-store-button
                                    unique-id="Banner2"
                                    type="apple"
                                />
                            </div>
                        </div>
                        <!-- 2 -->
                        <div
                            v-if="realIndex===2"
                            class="my-poa"
                        >
                            <div
                                class="sub-poa"
                                :class="{
                                    'sub-poa-active': needDh
                                }"
                            >
                                <div class="content-title">
                                    <span>{{ getI18n('bt3') }}</span>
                                    <span class="por">
                                        {{ getI18n('btt3') }}
                                        <img
                                            class="content-icon"
                                            src="../../component/img/shield-aman.png"
                                        >
                                    </span>
                                </div>
                                <div class="text">
                                    <span>{{ getI18n('desc') }}</span>
                                </div>
                            </div>

                            <div class="btn-group">
                                <app-store-button type="video" />
                                <app-store-button
                                    unique-id="Banner3"
                                    type="google"
                                />
                                <app-store-button
                                    unique-id="Banner3"
                                    type="apple"
                                />
                            </div>
                        </div>
                        <!-- 3 -->
                        <div
                            v-if="realIndex===3"
                            class="my-poa"
                        >
                            <div
                                class="sub-poa"
                                :class="{
                                    'sub-poa-active': needDh
                                }"
                            >
                                <div class="content-title">
                                    <span>{{ getI18n('bt4') }}</span>
                                    <span class="por">
                                        {{ getI18n('btt4') }}
                                        <img
                                            class="content-icon"
                                            src="../../component/img/shield-aman.png"
                                        >
                                    </span>
                                </div>
                                <div class="text">
                                    <span>{{ getI18n('desc4') }} <a
                                        class="click"
                                        @click="goPage('faqBeware')"
                                    >{{ getI18n('click') }}</a> {{ getI18n('desc4-1') }}</span>
                                </div>
                            </div>

                            <div class="btn-group">
                                <app-store-button type="video" />
                                <app-store-button
                                    unique-id="Banner3"
                                    type="google"
                                />
                                <app-store-button
                                    unique-id="Banner3"
                                    type="apple"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    slot="pagination"
                    class="swiper-pagination"
                />
            </swiper>
        </div>

        <div class="home-banner-mobile d-block d-md-none">
            <!-- <ec-image :webp="require('./img/banner-mn.webp')"> -->
            <img
                class="banner-mobile"
                src="./img/banner-mn.jpg"
            >
            <!-- </ec-image> -->
            <div class="content-wrap">
                <div class="por">
                    <div class="content-title-mobile">
                        <span>{{ getI18n('bt1') }}</span>
                    </div>
                    <img
                        src="../../component/img/shield-aman.png"
                        class="icon"
                    >
                </div>
                <div class="content-desc">
                    {{ getI18n('desc') }}
                </div>

                <div
                    class="btn-group ec-container"
                    :style="{
                        'justifyContent': $app.isIOS || $app.isAndroid ? 'center' : 'space-between'
                    }"
                >
                    <app-store-button
                        v-if="!$app.isIOS"
                        type="google"
                    />
                    <app-store-button
                        v-if="!$app.isAndroid"
                        type="apple"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import 'swiper/css/swiper.css';
import {Swiper, SwiperSlide} from 'vue-awesome-swiper';

import AppStoreButton from 'easycash/common/component/app-store-button';

export default {
    name: 'HomeBanner',

    components: {
        Swiper,
        SwiperSlide,
        AppStoreButton
    },

    data() {
        const {slideChangeTransitionStart} = this;

        return {
            needDh: true,
            realIndex: 0,
            swiperOptions: {
                speed: 500,
                // initialSlide: 3,
                autoplay: true,
                loop: true,
                effect: 'fade',
                fadeEffect: {
                    crossFade: true
                },
                on: {
                    slideChangeTransitionStart
                },
                pagination: {
                    bulletActiveClass: 'my-bullet-active',
                    el: '.swiper-pagination',
                    clickable: true
                }
            }
        };
    },

    methods: {
        getI18n(key, params) {
            return this.$t(`homeNew.banner.${key}`, params);
        },

        slideChangeTransitionStart() {
            const swiper = this.$refs.mySwiper?.swiperInstance;
            if (!swiper) return;
            this.needDh = false;
            this.realIndex = swiper.realIndex;
            this.$nextTick(() => {
                this.needDh = true;
            });
        },

        goPage(name) {
            this.$router.push({name});
        }
    }
};
</script>

<style lang="scss">
.home-banner-pc {
    .swiper-pagination-bullets {
        bottom: 1.66rem;
        display: flex;
        align-items: center;
        justify-content: center;

        .swiper-pagination-bullet {
            width: 0.08rem;
            height: 0.08rem;
        }

        .my-bullet-active {
            width: 0.12rem;
            height: 0.12rem;
            background-color: #00ff2e;
            opacity: 1;
        }
    }

    .click {
        cursor: pointer;
    }
}
</style>

<style lang="scss" scoped>
$banner-height: 8.1rem;

.mt20 {
    margin-top: 0.2rem;
}

.por {
    position: relative;
}

.btn-group {
    ::v-deep {
        .google-btn {
            background: #fff;

            &:hover,
            &:active {
                background: #f1f1f1;
            }
        }
    }
}

.home-banner-pc {
    height: $banner-height;
    overflow: hidden;
    position: relative;

    .slide-box {
        width: 100%;
        position: relative;

        .banner-two {
            position: absolute;
            bottom: 0;
            right: 1.8rem;
            width: 3.8rem;
            height: auto;
        }

        .bg-left {
            width: 50%;
            height: 100%;
            z-index: 1;
            background: linear-gradient(90deg, #f9efe6 40%, rgba(255, 247, 239, 0) 100%);
            position: absolute;
            left: 0;
            top: 0;

            @media screen and (max-width: $screen-edition) {
                width: 20%;
            }
        }

        .bg-right {
            width: 50%;
            height: 100%;
            z-index: 1;
            position: absolute;
            right: 0;
            top: 0;
            background: linear-gradient(to left, #f9f0e7 calc(100% - 700px), rgba(255, 249, 242, 0) calc(100% - 640px));

            @media screen and (max-width: $screen-edition) {
                width: 0;
            }
        }

        .bg-two {
            z-index: 2;
            width: 100%;
            height: 1rem;
            position: absolute;
            left: 0;
            bottom: 0;
            background: linear-gradient(to bottom, rgba(255, 245, 236, 0) 0%, #f9efe6 97%, #f9efe6 100%);
        }

        .slide {
            width: 100%;
            margin: 0 auto;
            max-width: 2600px;
            height: $banner-height;
            position: relative;
            box-sizing: border-box;
            overflow: hidden;

            .banner-one-new {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: 55% top;
            }
        }
    }

    .bg-one {
        background-color: #f9f0e7;
    }

    .bg-green-black {
        background-image: linear-gradient(to right, #00cd5b  0% 1440px, #444 1440px 100%);
    }

    .slide-bg-two {
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: linear-gradient(154deg, #fff 0%, #eee5dc 100%);

        .bg-line {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background-repeat: no-repeat;
            background-size: 14.4rem 6.5rem;
            background-position: right 0;
            background-image: url("./img/banner2-bg.png");

            @media screen and (max-width: $screen-edition) {
                background-size: 16.4rem 6.2rem;
                background-position: right -1.6rem top 0.5rem;
            }
        }
    }

    .content {
        z-index: 1;
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;

        .content-box {
            position: relative;
            height: 100%;
            margin-top: 1.6rem;

            .my-poa {
                position: absolute;
                left: 0;
                top: 0;

                .sub-poa {
                    transition: all 0.5s ease;
                    opacity: 0;
                }

                .sub-poa-active {
                    opacity: 1;
                }
            }

            .content-title {
                width: 7.3rem;
                position: relative;
                color: #050505;
                font-size: 0.72rem;
                font-weight: 900;
                line-height: 0.8rem;
                font-family: Mozaic HUM;
                font-style: normal;

                .content-icon {
                    width: 1.3rem;
                    height: auto;
                    position: absolute;
                    right: -1.05rem;
                    top: -0.1rem;
                }
            }

            .text {
                display: block;
                width: 4.6rem;
                margin-top: 0.3rem;
                color: #090909;
                font-size: 0.16rem;
                font-weight: 400;
                line-height: 0.24rem;
                position: relative;

                .content-icon-1 {
                    width: 1.3rem;
                    height: auto;
                    position: absolute;
                    right: -0.8rem;
                    top: -0.1rem;
                }

                .content-icon-2 {
                    width: 1.3rem;
                    height: auto;
                    position: absolute;
                    right: -1.1rem;
                    top: -0.1rem;
                }
            }

            .btn-group {
                display: flex;
                margin-top: 0.4rem;
            }
        }
    }
}

.home-banner-mobile {
    position: relative;
    background-color: #00a535;

    .banner-mobile {
        position: relative;
        width: 100%;
        height: 4.59rem;
    }

    .content-wrap {
        box-sizing: border-box;
        position: relative;
        z-index: 1;
        background-color: red;
        color: #050505;
        text-align: center;
        font-size: 0.24rem;
        font-weight: 900;
        line-height: 0.3rem;
        margin-top: -1rem;
        padding-top: 0.7rem;
        padding-bottom: 0.94rem;
        background: linear-gradient(to top, #00a535 80%, rgba(1, 205, 91, 0) 100%);

        .content-title-mobile {
            position: relative;
            width: 75%;
            text-align: center;
            margin: 0 auto;
        }

        .icon {
            width: 0.7rem;
            position: absolute;
            right: 0.13rem;
            top: -0.15rem;
        }

        .content-desc {
            margin: 0 auto;
            width: 70%;
            color: #090909;
            text-align: center;
            font-family: Mozaic HUM;
            font-size: 0.12rem;
            font-style: normal;
            font-weight: 400;
            line-height: 0.16rem;
            margin-top: 0.08rem;
        }

        .btn-group {
            display: flex;
            margin-top: 0.2rem;

            ::v-deep {
                .app-store-button {
                    width: 49.2%;
                    height: 0.47rem;

                    .channel {
                        height: 0.3rem;
                    }

                    .channel-btn {
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }
    }
}
</style>
