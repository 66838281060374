<!--
 * @Author: zhaoyang
 * @Date: 2023-06-28 19:55:46
 * @Last Modified by: zhaoyang
 * @Last Modified time: 2024-11-07 13:40:33
-->

<template>
    <div
        :class="{ 'home-activity-hide': blogList.length !== 2 }"
        class="home-activity ec-container"
    >
        <div class="activite-title">
            <span>{{ getI18n('title') }}</span>
            <div
                class="more"
                @click="goPage('blog')"
            >
                {{ getI18n('more') }}
            </div>
        </div>
        <div class="activite-wrap">
            <router-link
                v-for="(item, index) in blogList"
                :key="index"
                class="activite-box"
                :to="{
                    name: 'blogpage',
                    params: {blogPath: item.path}}"
            >
                <img
                    loading="lazy"
                    :src="item.headerImageUrl"
                    alt=""
                    class="left-img"
                >
                <div class="center-box">
                    <div class="title">
                        {{ formatTime(item.timeCreated) }}
                    </div>
                    <div class="desc">
                        {{ item.title }}
                    </div>
                </div>
            </router-link>
        </div>
    </div>
</template>

<script>
import dayjs from 'dayjs';

import ua from 'ssr-common/util/webview/ua-parsed';

import 'dayjs/locale/id';

export default {
    name: 'HomeActivity',

    props: {
        blogList: {
            type: Array,
            default: () => []
        }
    },

    data() {
        return {
            ua
        };
    },

    methods: {
        getI18n(key, params) {
            return this.$t(`homeNew.activity.${key}`, params);
        },

        goPage(name) {
            this.$router.push({name});
        },

        formatTime(time) {
            return dayjs(time).locale(this.$i18n.locale).format('DD MMMM YYYY');
        }
    }

};
</script>

<style lang="scss" scoped>
.home-activity {
    margin-top: 1rem;

    @media screen and (max-width: $screen-md) {
        margin-top: 0.4rem;
    }

    &-hide {
        display: none;
    }

    .activite-title {
        color: #000;
        font-size: 0.56rem;
        font-weight: 700;
        line-height: 0.8rem;
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media screen and (max-width: $screen-md) {
            font-size: 0.24rem;
            line-height: 1;
        }

        .more {
            width: 0.79rem;
            height: 0.4rem;
            border-radius: 0.3rem;
            border: 1px solid #1e1e1e;
            color: #000;
            text-align: center;
            font-size: 0.14rem;
            font-family: Quicksand;
            font-weight: 400;
            line-height: 0.4rem;
            cursor: pointer;

            &:hover,
            &:active {
                background-color: #f1f1f1;
            }

            @media screen and (max-width: $screen-md) {
                width: 0.4rem;
                height: 0.2rem;
                font-size: 0.07rem;
                border-radius: 0.1rem;
                line-height: 0.2rem;
            }
        }
    }

    .activite-wrap {
        margin-top: 0.2rem;
        display: flex;
        justify-content: space-between;

        @media screen and (max-width: $screen-md) {
            flex-direction: column;
            justify-content: unset;
        }

        .activite-box {
            display: flex;
            align-items: center;
            width: 49%;
            box-sizing: border-box;
            border-radius: 0.32rem;
            background: #000;
            padding: 0.1rem 0.4rem 0.1rem 0.1rem;

            &:hover {
                background: #333;
            }

            @media screen and (max-width: $screen-md) {
                width: 100%;
                border-radius: 0.16rem;
                padding: 0.05rem 0.1rem 0.05rem 0.05rem;
                margin-bottom: 0.1rem;
            }

            .left-img {
                width: 2rem;
                height: 1.4rem;
                border-radius: 0.24rem;
                object-fit: cover;

                @media screen and (max-width: $screen-md) {
                    width: 1rem;
                    height: 0.7rem;
                    border-radius: 0.16rem;
                }
            }

            .center-box {
                flex: 1;
                color: #fff;
                margin-left: 0.12rem;

                @media screen and (max-width: $screen-md) {
                    margin-left: 0.06rem;
                }

                .title {
                    font-size: 0.14rem;
                    font-weight: 400;
                    line-height: 1;

                    @media screen and (max-width: $screen-md) {
                        font-size: 0.12rem;
                        line-height: 0.18rem;
                        font-weight: 300;
                    }
                }

                .desc {
                    font-size: 0.16rem;
                    font-weight: 700;
                    line-height: 0.24rem;

                    @media screen and (max-width: $screen-md) {
                        font-size: 0.14rem;
                        line-height: 0.18rem;
                    }
                }
            }
        }
    }
}
</style>
